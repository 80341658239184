#confetti {
    position: fixed;
    z-index: 1000;
}

.win-card {
    position: relative;
    margin: 0 auto;
    top: 20%;
    border: 15px solid #ff6d74;
    width: 450px;
    height: auto;
    padding: 10px 5px;
    border-radius: 11% 10% 10% 10% / 49% 49% 51% 51%;
    background: #ffa1a5;
    animation: fade 1s;
}

.win-card>h2 {
    position: absolute;
    background: linear-gradient(45deg, #6842FF, #ff429a);
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    color: #ff9498;
    width: max-content;
    padding: 5px 10px;
    border-radius: 8px;
    box-shadow: 5px 5px 0 0 black;
    animation: win 1.2s;
}

.win-card>span {
    display: block;
    width: 80%;
    text-align: center;
    font-size: 40px;
    color: white;
    background: linear-gradient(green, rgb(68, 218, 68));
    border-radius: 5px;
    border: 5px solid green;
    margin: 15px auto;
    cursor: pointer;
    z-index: 3;
}

.win-card>span:last-child {
    background: linear-gradient(rgb(206, 0, 0), red);
    border: 5px solid rgb(200, 0, 0);
}

.win-card>span:hover {
    background: linear-gradient(rgb(0, 110, 0), rgb(53, 170, 53));
    font-weight: 900;
}

.win-card>span:last-child:hover {
    background: linear-gradient(rgb(156, 0, 0), rgb(221, 0, 0));
    border: 5px solid rgb(200, 0, 0);
}

.drop-content button {
    border: none;
    border-radius: 50%;
    background: none;
}

#hint {
    animation: hint 1s infinite;
}

@keyframes hint {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}

@media screen and (max-width: 760px) {
    .win-card {
        top: 20%;
        width: 95dvw;
    }

    .win-card>span {
        font-size: 30px;
    }
}

@keyframes win {
    0% {
        letter-spacing: 0px;
    }

    20% {
        letter-spacing: 5px;
    }

    40% {
        letter-spacing: -4px;
    }

    80% {
        letter-spacing: 3px;
    }

    100% {
        letter-spacing: 0px;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}