.dashboard {
    width: 90vw;
    position: relative;
    border-radius: 1rem 1rem 1rem 1rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin: 0 auto;
    margin-top: 2%;
}

.dashboard-cards {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
}

.d-card {
    width: 300px;
    padding: 10px 20px;
    border-radius: 10px;
    background: #FD7F83;
}

@media screen and (max-width: 760px) {
    .d-card {
        width: auto;
        margin: 10px 0;
    }
    .dashboard-cards {
        display: block;
        width: 100%;
    }
}