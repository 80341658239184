/* 
    This CSS file contains styles for 
    - Head tags
    - Button tags
    - Animations
    - Fonts imports
    - Bootstrap icons imports
*/

@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Iso&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lemon&display=swap');
/* Bootstrap icons */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

body {
    margin: 0;
    padding: 0;
    font-family: 'Amaranth', sans-serif;
    overflow-x: hidden;
    background: linear-gradient(45deg, #fff8fb, #ffe8e8);
}

.alert {
    display: block;
}

/* scroll bars */

/* width */
::-webkit-scrollbar {
    width: 5px;
}

::-moz-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffadad;
}

::-moz-scrollbar-track {
    background: #ffadad;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #8b93ff;
}

::-moz-scrollbar-thumb {
    background: #8b93ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ff6a6a;
}

::-moz-scrollbar-thumb:hover {
    background: #ff6a6a;
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -5;
}

.head-text {
    font-family: 'Lemon', cursive;
}

h1 {
    font-size: 20px;
}

h2 {
    font-family: 'Lemon', cursive;
    font-size: 4rem;
    color: #0a103f;
    text-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

p {
    color: #0C0D14;
}

main {
    margin-top: 4%;
}

a {
    color: #6842FF;
    text-decoration: none;
    transition: all .2s;
    font-weight: 700;
}

a:hover {
    color: #E45A84;
}

/* #email-field {
    background: #ff9296;
    color: #fff;
    font-size: large;
    border: none;
    height: 36px;
    border-radius: 10px;
    position: relative;
    top: 2px;
} */

.cta {
    padding: 8px 20px;
    font-family: 'Lemon', cursive;
    font-size: large;
    border-radius: 5px;
    margin: 10px 10px;
}

.cta-1 {
    background: #ff6c71;
    color: #fff;
    transition: .3s ease;
    border: none;
    box-shadow: 5px 5px 0 0 #6842FF;
    height: 65px;
    min-width: 130px;
}

.cta-1:hover {
    position: relative;
    top: 5px;
    left: 5px;
    transition: .3s ease;
    box-shadow: none;
}

.cta-2 {
    background: #6842FF;
    color: #fff;
    transition: .3s ease;
    border: none;
    box-shadow: 5px 5px 0 0 #ff6c71;
    height: 65px;
    min-width: 130px;
}

.cta-2:hover {
    position: relative;
    top: 5px;
    left: 5px;
    transition: .3s ease;
    box-shadow: none;
}

.btn-sultry {
    background: #6842FF;
    border-radius: 10px;
    padding: 5px 10px;
    width: max-content;
    transition: .2s ease;
    color: #fff !important;
    font-family: 'Lemon', cursive;
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.btn-coin {
    background: #FFC1C4;
    position: relative;
    color: #2e1d70 !important;
    border: 1px solid rgba(255, 255, 255, 0.3);
    font-size: large;
    animation: bounce .7s;
}

.btn-sultry:hover,
.btn-coin:hover {
    background: #a18aff;
    transition: .2s ease;
    color: #ff6c71 !important;
}

.font-2 {
    font-family: 'Lemon', cursive;
}

.coins {
    color: #6842FF;
}

.rand_char {
    position: fixed;
    left: 5px;
    bottom: 0px;
    width: 22%;
    z-index: -3;
}

.rand_char_2 {
    position: fixed;
    right: 5px;
    bottom: 0px;
    width: 22%;
    z-index: -3;
}

.rand_char>img {
    width: 35%;
    transform: translateY(30px);
    opacity: 0.7;
}

.rand_char_2>img {
    width: 40%;
    transform: translateY(30px);
    opacity: 0.7;
}

#floating-nav {
    background: #ff6c7133;
    border: 1px solid #e45a8342;
    backdrop-filter: blur(5px);
    padding: 0.5rem 0.2rem;
    border-radius: 15px;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    animation: hover 1s infinite;
}

#floating-nav button {
    border-radius: 10px;
    border: none;
    background: #ff6a6a;
    padding: 0.3rem 0.6rem;
    margin: 0 0.3rem;
}

#floating-nav button i {
    font-size: 1.2rem;
    color: #6842FF;
    filter: drop-shadow(1px 1px 0 #000)
}

#floating-nav button span {
    position: relative;
    top: -1px;
}


@keyframes hover {
    0% {
        bottom: 80px;
    }

    50% {
        bottom: 83px;
    }

    100% {
        bottom: 80px;
    }
}

@media screen and (max-width: 760px) {

    h1 {
        font-size: 16px;
    }

    h2 {
        font-size: 2.5rem;
    }

    .rand_char {
        width: 80%;
        transform: translateX(-50%);
    }

    .rand_char_2 {
        width: 80%;
        transform: translateX(50%);
    }

    .cta {
        padding: 4px 8px;
        font-family: 'Lemon', cursive;
        font-size: 16px;
        border-radius: 5px;
        margin: 12px 12px;
        min-width: 80px;
        display: inline-block;
        height: 40px;
    }

    .cta-1 {
        height: fit-content;
        border: none;
        color: #fff;
        width: 100%;
        padding: 10px 0;
        background: #ff6c71;
        box-shadow: 5px 5px 0 0 #6842FF;
        transition: .3s ease;
    }

    .cta-1:hover {
        position: relative;
        top: 5px;
        left: 5px;
        transition: .3s ease;
        box-shadow: none;
    }

    .cta-2 {
        width: 100%;
        height: fit-content;
        color: #fff;
        padding: 10px 10px;
        border: none;
        background: #6842FF;
        box-shadow: 5px 5px 0 0 #ff6c71;
        transition: .3s ease;
    }

    .cta-2:hover {
        position: relative;
        top: 5px;
        left: 5px;
        transition: .3s ease;
        box-shadow: none;
    }

    .btn-sultry {
        background: #6842FF;
        border-radius: 10px;
        width: max-content;
        transition: .2s ease;
        color: #fff !important;
        font-family: 'Lemon', cursive;
        border: 2px solid rgba(255, 255, 255, 0.3);
    }

    #floating-nav {
        padding: 0.3rem 0rem;
        border-radius: 15px;
        position: fixed;
        bottom: 80px;
        width: max-content;
        left: 50%;
        transform: translateX(-50%);
    }

    #floating-nav button {
        border-radius: 10px;
        border: none;
        padding: 0.1rem 0.4rem;
        margin: 0 0.3rem;
    }
}

@keyframes bounce {
    0% {
        top: 0px;
    }

    20% {
        top: 12px;
    }

    40% {
        top: -6px;
    }

    60% {
        top: 6px;
    }

    70% {
        top: -4px;
    }

    90% {
        top: 3px;
    }

    100% {
        top: 0px;
    }
}

/* STYLING FOR BACKGROUND HEADS */

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background span {
    width: 14vmin;
    height: 14vmin;
    border-radius: 20vmin;
    opacity: 0.9;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    animation: move;
    animation-duration: 45;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.background span:nth-child(0) {
    color: #E45A84;
    background-image: url('../assets/characters/scene-2/mary-1.png');
    top: 75%;
    left: 69%;
    animation-duration: 14s;
    animation-delay: -19s;
    transform-origin: -17vw 10vh;
    box-shadow: -40vmin 0 5.501472596220119vmin currentColor;
}

.background span:nth-child(1) {
    background-image: url('../assets/characters/scene-2/moody-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #E45A84;
    top: 46%;
    left: 40%;
    animation-duration: 38s;
    animation-delay: -10s;
    transform-origin: -2vw -8vh;
    box-shadow: 40vmin 0 5.679690424413636vmin currentColor;
}

.background span:nth-child(2) {
    background-image: url('../assets/characters/scene-2/platt-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFACAC;
    top: 76%;
    left: 65%;
    animation-duration: 26s;
    animation-delay: -45s;
    transform-origin: 5vw 17vh;
    box-shadow: 40vmin 0 5.278150560066035vmin currentColor;
}

.background span:nth-child(3) {
    background-image: url('../assets/characters/scene-2/sandowsky-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFACAC;
    top: 8%;
    left: 70%;
    animation-duration: 30s;
    animation-delay: -8s;
    transform-origin: -24vw 20vh;
    box-shadow: 40vmin 0 5.104788825068322vmin currentColor;
}

.background span:nth-child(4) {
    background-image: url('../assets/characters/scene-2/sophia-1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #583C87;
    top: 88%;
    left: 97%;
    animation-duration: 16s;
    animation-delay: -18s;
    transform-origin: 25vw 25vh;
    box-shadow: 40vmin 0 5.75752327056556vmin currentColor;
}

.background span:nth-child(5) {
    background-image: url('../assets/characters/scene-1/dr-dorsey_1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #E45A84;
    top: 59%;
    left: 48%;
    animation-duration: 47s;
    animation-delay: -27s;
    transform-origin: -4vw 15vh;
    box-shadow: 40vmin 0 5.153882328566997vmin currentColor;
}

.background span:nth-child(6) {
    background-image: url('../assets/characters/scene-1/dr-terrence_1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #E45A84;
    top: 68%;
    left: 20%;
    animation-duration: 25s;
    animation-delay: -2s;
    transform-origin: -24vw -1vh;
    box-shadow: 40vmin 0 5.258130811369123vmin currentColor;
}

.background span:nth-child(7) {
    background-image: url('../assets/characters/scene-1/keisha_1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #583C87;
    top: 74%;
    left: 18%;
    animation-duration: 43s;
    animation-delay: -26s;
    transform-origin: -18vw -10vh;
    box-shadow: -40vmin 0 5.584986566656156vmin currentColor;
}

.background span:nth-child(8) {
    background-image: url('../assets/characters/scene-1/jose_1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #583C87;
    top: 43%;
    left: 97%;
    animation-duration: 36s;
    animation-delay: -16s;
    transform-origin: 9vw 9vh;
    box-shadow: 40vmin 0 5.36218087085358vmin currentColor;
}

.background span:nth-child(9) {
    background-image: url('../assets/characters/scene-1/liu_1.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #E45A84;
    top: 24%;
    left: 38%;
    animation-duration: 31s;
    animation-delay: -15s;
    transform-origin: -3vw -17vh;
    box-shadow: -40vmin 0 5.3340457762554125vmin currentColor;
}