.char-card {
    width: 28rem;
    height: max-content;
    position: fixed;
    font-size: large;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 1rem;
    border: 2px solid #968aff;
    background: linear-gradient(45deg, #ffc0cb60, #e706a460);
    padding: 5px 20px 5px 10px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 5;
    animation: charIn .4s ease;
}

.char-card img {
    width: 100px;
    position: absolute;
    left: 90%;
}

@media screen and (max-width: 760px) {
    .char-card {
        font-size: smaller;
        width: 13rem;
    }

    .char-card img {
        width: 100px;
        position: absolute;
        left: 85%;
    }
}

@keyframes charIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}