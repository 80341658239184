#box {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    gap: 1rem;
}

.card {
    height: 200px;
    width: 300px;
    margin: 1rem 0 1rem;
    border-radius: 10px;
    background: #FD7F83;
    transition: all .3s ease;
    box-shadow: 5px 5px 0 0 #6842FF;
}

.is_locked {
    width: auto;
    height: 100%;
    border-radius: 10px;
    background: rgba(40, 44, 52, 0.8);
}

.card:hover {
    position: relative;
    top: 5px;
    left: 5px;
    box-shadow: 2px 2px 0 0 #6842FF;
}

.locked_card {
    height: 200px;
    width: 300px;
    margin: 1rem 0 1rem;
    border-radius: 10px;
    background: #FD7F83;
    transition: all .3s ease;
    box-shadow: 5px 5px 0 0 #6842FF;
}

@media screen and (max-width: 860px) {
    #box {
        justify-content: center;
    }
}