#pairs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#pairs>div {
    background: #ffffff33;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 3px solid #ff6c71;
    backdrop-filter: blur(5px);
    display: inline-block;
    -webkit-backdrop-filter: blur(5px);
}

.scene-play {
    padding: 10px 20px;
    border-radius: 1rem 1rem 1rem 1rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin: 2% auto;
}

.play-btn {
    background: #6842FF;
    box-shadow: 3px 3px 0 0 black;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: inline-block;
}

.play-btn:hover {
    position: relative;
    top: 3px;
    left: 3px;
    box-shadow: none;
}

#share {
    background: #6842FF;
    box-shadow: none;
    border-radius: 5px;
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;
    display: inline-block;
}

/* Popup container - can be anything you want */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* The actual popup */
.popup .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #ffd3d3;
    color: #fff;
    border: 1px solid #555;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}