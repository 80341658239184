@keyframes slideIn {
    from {
        opacity: 0;
        bottom: -20px;
    }

    to {
        opacity: 0.8;
        bottom: 50px;
    }
}

#game-message {
    position: fixed;
    opacity: 0.8;
    border-radius: 0.5rem;
    border: 5px solid #ff6d74;
    background: #ffa1a5;
    box-shadow: 0 0 10px 0 rgb(117, 117, 243);
    font-size: 20px;
    width: max-content;
    animation: slideIn .2s;
    padding: 0 8px;
    display: none;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
}