.flipcard {
    width: 105px;
    height: 105px;
    display: inline-block;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 2px solid #ff6c71;
    border-radius: 5px;
    transition: .2s ease;
    perspective: 500px;
}

.flip-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.2s;
    transform-style: preserve-3d;
}

.turn {
    transform: rotateY(180deg);
}

.flip-front,
.flip-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-front {
    background-image: url("../assets/null_user.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-clip: border-box;
    background-clip: border-box;
}

.flip-back {
    color: white;
    transform: rotateY(180deg);
}

.flip-back img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 760px) {
    .flipcard {
        width: 90px;
        height: 90px;
        margin: 10px 0;
    }
}