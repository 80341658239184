.scene {
    width: 80vw;
    padding: 10px 20px;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin: 5% auto;
    margin-top: 5%;
}

.avatar-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

#clicker {
    position: absolute;
    display: inline-block;
    animation: clicker 1s ease-out infinite;
    left: 90px;
    top: 270px;
    font-size: 3rem;
}

@keyframes clicker {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.3, 1.3);
    }

    100% {
        transform: scale(1, 1);
    }
}

@media screen and (max-width: 760px) {
    .scene {
        margin-top: 20%;
        width: 80vw;
    }

    #clicker {
        left: 80px;
        top: 380px;
    }
}

/* Popup container - can be anything you want */
.popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* The actual popup */
.popup .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
}

/* Toggle this class - hide and show the popup */
.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}