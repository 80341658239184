.avatar {
    width: 120px;
    height: 120px;
    margin: 10px;
    display: inline-block;
    border-radius: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transition: all .2s ease;
    overflow: hidden;
    border: 3px inset #6842FF;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar:hover {
    transform: scale(1.05, 1.05);
    border: none;
}

@media screen and (max-width: 760px) {
    .avatar {
        width: 90px;
        height: 90px;
        margin: 5px 10px;
        top: 0;
    }
}