.back-drop {
    position: fixed;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(5px);
    overflow-x: hidden;
    z-index: 999;
}

.pause-card {
    position: relative;
    margin: 0 auto;
    top: 25%;
    border: 15px solid #ff6d74;
    width: 450px;
    height: auto;
    padding: 10px 5px;
    border-radius: 11% 10% 10% 10% / 49% 49% 51% 51%;
    background: #ffa1a5;
    animation: fade 1s;
}

.pause-card>span {
    display: block;
    width: 80%;
    text-align: center;
    font-size: 40px;
    color: white;
    background: linear-gradient(green, rgb(68, 218, 68));
    border-radius: 5px;
    border: 5px solid green;
    margin: 15px auto;
    cursor: pointer;
    z-index: 3;
}

.pause-card>span:last-child {
    background: linear-gradient(rgb(206, 0, 0), red);
    border: 5px solid rgb(200, 0, 0);
}

.pause-card>span:hover {
    background: linear-gradient(rgb(0, 110, 0), rgb(53, 170, 53));
    font-weight: 900;
}

.pause-card>span:last-child:hover {
    background: linear-gradient(rgb(156, 0, 0), rgb(221, 0, 0));
    border: 5px solid rgb(200, 0, 0);
}


.back-drop img {
    position: absolute;
    width: 100px;
    height: 100px;
    opacity: 0.5;
    margin: 20px;
}

.share:hover .drop-content {
    display: block;
}

.share .drop-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    border-radius: 1rem;
    width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.drop-content button:hover {
    transform: scale(1.5, 1.5);
    transition: 0.1s;
}

@media screen and (max-width: 760px) {
    .pause-card {
        top: 20%;
        width: 95dvw;
    }

    .pause-card>span {
        font-size: 30px;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}