/* Login Styles */
#login-form form {
    width: 40%;
    padding: 10px 20px;
    position: relative;
    border-radius: 1rem 1rem 1rem 1rem;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin: 0 auto;
    margin-top: 10%;
}

#login-form input {
    width: 100%;
    border: none;
    padding: 10px;
    margin: 20px 0;
    color: white;
    display: block;
    font-size: 20px;
    text-align: center;
    border-radius: 15px;
    background: rgb(44, 43, 67);
    transition: .8s ease;
}

#login-form input:focus {
    outline: 5px solid #FD7F83;
    transition: .8s ease;
}

@media screen and (max-width: 760px) {
    #login-form form {
        margin-top: 30%;
        width: auto;
    }

    #login-form button {
        /* margin-top: 6rem; */
    }
}