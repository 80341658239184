.story {
    background: #ffbbbb6d;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: 2px solid #674fc933;
    transition: .2s ease;
}

.story:hover {
    background: #6842fe6d;
}